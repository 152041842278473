<template>
  <div class="home-page">
    <header>
      <div class="content-wrapper">
        <h1>NIOAI</h1>
        <nav>
          <a href="https://img.ycyaw.com/free/2024/08/31/66d30da715ab7.jpg">联系客服</a>
        </nav>
      </div>
    </header>
    
    <section id="projects" class="projects">
      <div class="carousel-container">
        <Carousel 
          :items-to-show="1"
          :wrap-around="true"
          :transition="500"
          snap-align="center"
          :autoplay="5000"
          :pause-autoplay-on-hover="true"
          :breakpoints="carouselBreakpoints"
        >
          <Slide v-for="project in projects" :key="project.id">
            <div class="project-card">
              <img :src="project.imageUrl" alt="Project Image">
            </div>
          </Slide>
        </Carousel>
      </div>
    </section>

    <section id="products" class="products">
      <div class="content-wrapper">
        <h2>产品列表</h2>
        <p>独家逆向技术无需魔法直登各家官网！非API套壳站！100%还原官网体验！支持日卡或注册体验试用！</p>
        
        <div class="product-categories">
          <button 
            v-for="category in categories" 
            :key="category.id"
            @click="setActiveCategory(category.id)"
            :class="{ active: activeCategory === category.id }"
          >
            {{ category.name }}
          </button>
        </div>

        <div class="product-content">
          <div class="sidebar left"></div>
          <div class="main-content">
            <div v-for="category in categories" :key="category.id" v-show="activeCategory === category.id">
              <div class="category-nav-card" @click="navigateToCategory(category.url)">
                <div class="category-image-container">
                  <img :src="category.imageUrl" :alt="category.name">
                  <div class="banner">点击进入</div>
                </div>
                <div class="category-info">
                  <h3>{{ category.name }}</h3>
                  <p>{{ category.description }}</p>
                </div>
              </div>

              <div class="product-grid">
                <a 
                  v-for="product in paginatedProducts" 
                  :key="product.id" 
                  :href="product.url"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="product-item"
                >
                  <img :src="product.imageUrl" :alt="product.name" class="product-image">
                  <h3 class="product-name">{{ product.name }}</h3>
                  <p class="product-sales">已售: {{ Math.floor(Math.random() * 10000) }}</p>
                  <p class="product-time">{{ Math.floor(Math.random() * 60) }}分钟前有人购买</p>
                  <div class="product-features">
                    <span v-for="(feature, index) in product.features" :key="index" class="feature-tag">
                      {{ feature }}
                    </span>
                  </div>
                  <div class="product-price">
                    <p class="original-price">原价 ¥{{ product.originalPrice.toFixed(2) }}</p>
                    <p class="current-price">¥{{ product.price.toFixed(2) }}/{{ product.period }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="sidebar right"></div>
        </div>
      </div>
    </section>
    <footer class="footer">
      <div class="content-wrapper">
        <div class="footer-content">
          <div class="footer-description">
            <p>选择 NIOAI，开启智能新时代！</p>
          </div>
          <div class="footer-qrcode">
            <img src="https://img.ycyaw.com/free/2024/08/31/66d30da715ab7.jpg" alt="客服二维码" style="width: 100px; height: 100px;">
            <p>扫码联系客服</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
  name: 'HomePage',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      projects: [
        { id: 1, imageUrl: "https://www.bosshunting.com.au/cdn-cgi/imagedelivery/izM8XxyLg9MD6py1ribxJw/www.bosshunting.com.au/2023/03/openai-gpt-4.jpg/w=1200,h=675" },
        { id: 2, imageUrl: "https://cdn2.yicanggongyi.com/picture/5556e3ac9a513ee3200bdaea88f4ee0d-1724401115214.png?x-oss-process=image/resize,h_400" },
        { id: 3, imageUrl: "https://img.ycyaw.com/free/2024/08/31/66d2e2ffcd51f.png" },
        { id: 4, imageUrl: "https://img.ycyaw.com/free/2024/08/31/66d2e5b9617b2.png" },
        { id: 5, imageUrl: "https://img.ycyaw.com/free/2024/08/31/66d2e7cbbd5bf.png" }
      ],
      categories: [
        { 
          id: 1, 
          name: "Anthropic", 
          imageUrl: "https://img.ycyaw.com/free/2024/08/31/66d2d6e48817a.jpg",
          description: "  Claude AI是Chat GPT的最强对手， 对于长文本处理，Claude AI会比Chat GPT更快速、更准确。对于编程代码类问题，Claude AI的UI做的更好，并且逻辑会更好。",
          url: "https://klaude.pro/"
        },
        { 
          id: 2, 
          name: "Open AI", 
          imageUrl: "https://img.ycyaw.com/free/2024/09/01/66d40c929fc26.png",
          description: "  OpenAI开发的智能对话模型，通过AI技术，可以与用户进行自然语言对话，实现智能聊天、知识问答、代码生成等功能。是自AI流行以来最火的AI模型",
          url: "https://chatgpts.pro/"
        },
      ],
      activeCategory: 1,
      currentPage: 1,
      itemsPerPage: 6,
      products: {
        1: [
          { id: 1, name: "Claude独享月卡", imageUrl: "https://img.ycyaw.com/free/2024/12/18/6762c3002218b.png", url: "https://shop.xbbai.pro", features: ['单月超值'], price: 89, originalPrice: 108, period: "1月" },
          { id: 2, name: "Claude独享季卡", imageUrl: "https://img.ycyaw.com/free/2024/12/18/6762c3002218b.png", url: "https://shop.xbbai.pro", features: ['方便快捷'], price: 258, originalPrice: 298, period: "3月" },
          { id: 3, name: "Claude独享年卡", imageUrl: "https://img.ycyaw.com/free/2024/12/18/6762c3002218b.png", url: "https://shop.xbbai.pro", features: ['限时优惠'], price: 888, originalPrice: 998, period: "12月" },
          { id: 4, name: "Claude天卡体验", imageUrl: "https://img.ycyaw.com/free/2024/12/18/6762c3002218b.png", url: "https://shop.xbbai.pro", features: ['联系客服'], price: 0, originalPrice: 0, period: "24H" },
        ],
        2: [
          { id: 8, name: "ChatGPT独享月卡", imageUrl: "https://img.ycyaw.com/free/2024/12/18/6762c2a928509.png", url: "https://shop.xbbai.pro", features: ['单月超值'], price: 89, originalPrice: 108, period: "1月" },
          { id: 9, name: "ChatGPT独享季卡", imageUrl: "https://img.ycyaw.com/free/2024/12/18/6762c2a928509.png", url: "https://shop.xbbai.pro", features: ['方便快捷'], price: 258, originalPrice: 298, period: "3月" },
          { id: 10, name: "ChatGPT独享年卡", imageUrl: "https://img.ycyaw.com/free/2024/12/18/6762c2a928509.png", url: "https://shop.xbbai.pro", features: ['限时优惠'], price: 888, originalPrice: 998, period: "12月" },
          { id: 11, name: "ChatGPT天卡体验", imageUrl: "https://img.ycyaw.com/free/2024/12/18/6762c2a928509.png", url: "https://shop.xbbai.pro", features: ['联系客服'], price: 0, originalPrice: 0, period: "24H" },
        ]
      },
      carouselBreakpoints: {
        768: {
          itemsToShow: 1,
          snapAlign: 'center',
        }
      }
    }
  },
  computed: {
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.products[this.activeCategory].slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.products[this.activeCategory].length / this.itemsPerPage);
    }
  },
  methods: {
    generateFeatures() {
      const allFeatures = ['超值套餐','必备订阅', '单月超值', '高速稳定', '无需门槛', '全中文体验', '学生优惠', '支持直播'];
      return allFeatures.sort(() => 0.5 - Math.random()).slice(0, 1);
    },
    setActiveCategory(categoryId) {
      this.activeCategory = categoryId;
      this.currentPage = 1;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    navigateToCategory(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: rgb(244, 247, 255);

  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
  }

  header {
    background-color: rgb(244, 247, 255);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    padding: 10px 0;
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: all 0.3s ease;

    .content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h1 {
      font-size: 2.5em;
      margin: 0;
      color: #000000;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: bold;
    }

    nav {
      a {
        color: #2c3e50;
        text-decoration: none;
        margin-left: 30px;
        font-weight: bold;
        font-size: 1.1em;
        transition: color 0.3s ease;
        position: relative;

        &:hover {
          color: #3498db;
        }

        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 2px;
          bottom: -5px;
          left: 0;
          background-color: #3498db;
          transition: width 0.3s ease;
        }

        &:hover:after {
          width: 100%;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .projects {
    margin-bottom: 0px;
    width: 100%;
    background-color: rgb(244, 247, 255);
    padding: 80px 0;
    overflow: hidden;

    .carousel-container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      overflow: visible;
    }

    // PC端样式
    @media (min-width: 769px) {
      :deep(.carousel__viewport) {
        overflow: visible !important;
      }

      :deep(.carousel__track) {
        display: flex;
        align-items: center;
      }

      :deep(.carousel__slide) {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
      }

      :deep(.carousel__item) {
        width: calc(100% / 3);
        padding: 0 15px;
        box-sizing: border-box;
      }

      :deep(.carousel__slide--active) {
        z-index: 20;
        .project-card {
          transform: scale(1.1);
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
        }
      }

      :deep(.carousel__slide:not(.carousel__slide--active)) {
        opacity: 0.7;
        z-index: 10;
      }

      .project-card {
        background-color: #ffffff;
        border-radius: 15px;
        padding: 5px;
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }

    // 移动端样式
    @media (max-width: 768px) {
      padding: 0px 0;
      background-color: #e8f0fe;

      .carousel-container {
        overflow: hidden;
      }

      :deep(.carousel__viewport) {
        overflow: hidden;
      }

      :deep(.carousel__track) {
        display: flex;
      }

      :deep(.carousel__slide) {
        flex: 0 0 100%;
        max-width: 100%;
        opacity: 1 !important;
        z-index: 1;
      }

      .project-card {
        height: auto;
        padding: 0;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
        transform: none !important;

        img {
          width: 100%;
          height: auto;
          aspect-ratio: 16 / 9;
          object-fit: cover;
        }
      }
    }
  }

  .products {
    h2 {
      text-align: center;
      margin-top: 20px !important;
      margin-bottom: 40px;
      font-size: 2em;
      color: #2c3e50;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 60px;
        height: 3px;
        background-color: #3498db;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .product-categories {
      display: flex;
      justify-content: space-around;
      margin-bottom: 30px;

      button {
        background-color: #ffffff;
        border: 2px solid #000000;
        padding: 12px 24px;
        margin: 0 40px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 1em;
        color: #000000;
        font-weight: bold;
        width: 35%;

        &.active {
          background-color: #000000;
          color: rgb(255, 255, 255);
        }

        &:hover {
          background-color: #000000;
          color: rgb(255, 255, 255);
        }
      }
    }

    .category-nav-card {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px;
      p, h3 {
        color: rgb(0, 0, 0) !important;
      }

      .category-image-container {
        width: 50%;
        position: relative;
        overflow: hidden;
        border: 1px solid white;

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
          border-radius: 8px;
          filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.1));
        }
        
        .banner {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgb(0, 0, 0);
          color: white;
          letter-spacing: 20px;
          padding: 5px 0;
          text-align: center;
          font-size: 1.2em;
          font-weight: 100;
          transition: all 0.3s ease;
          border: 1px white;
          cursor: pointer;
        }
      }

      .category-info {
        width: 45%;
        text-align: left;
        margin-left: 5%;
        font-size: large;

        h3 {
          margin-bottom: 15px;
          color: #fff;
          font-size: 1.8em;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
          text-align: center !important;
        }

        p {
          margin-bottom: 20px;
          color: rgba(63, 83, 99) !important;
          text-indent: 2em;
          font-size: 1.1em;
          line-height: 1.8;
          margin-top: 40px;
        }
      }
    }

    .product-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 30px;
      margin-bottom: 40px;
    }

    .product-item {
      background-color: #ffffff;
      border-radius: 15px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;
      text-decoration: none;
      color: inherit;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      }

      .product-image {
        width: 100px;
        height: 100px;
        object-fit: contain;
        border-radius: 50%;
        margin-bottom: 20px;
        background-color: #f0f4f8;
        padding: 15px;
        transition: all 0.3s ease;
      }

      &:hover .product-image {
        transform: scale(1.1);
      }

      .product-name {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        color: #2c3e50;
      }

      .product-sales,
      .product-time {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 8px;
      }

      .product-features {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        margin-bottom: 15px;

        .feature-tag {
          background-color: #e8f0fe;
          color: #3498db;
          padding: 6px 12px;
          border-radius: 15px;
          font-size: 12px;
          font-weight: bold;
          transition: all 0.3s ease;

          &:hover {
            background-color: #3498db;
            color: #ffffff;
          }
        }
      }

      .product-price {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        .original-price {
          font-size: 14px;
          color: #999;
          text-decoration: line-through;
        }

        .current-price {
          font-size: 18px;
          font-weight: bold;
          color: #e74c3c;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .home-page {
    header {
      padding: 15px 0;

      .content-wrapper {
        flex-direction: column;
        align-items: center;
      }

      h1 {
        font-size: 2em;
        margin-bottom: 15px;
      }

      nav {
        a {
          margin: 0 10px;
          font-size: 1em;
        }
      }
    }

    .products {
      .product-categories {
        flex-wrap: wrap;

        button {
          margin: 5px;
          font-size: 14px;
          padding: 10px 20px;
          width: 45%;
        }
      }

      .category-nav-card {
        flex-direction: column;
        align-items: center;
        padding: 0px;

        .category-image-container {
          width: 100%;
          margin-bottom: 20px;
        }

        .category-info {
          display: none;
        }
      }

      .product-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
      }

      .product-item {
        padding: 15px;

        .product-image {
          width: 80px;
          height: 80px;
        }

        .product-name {
          font-size: 16px;
        }

        .product-sales,
        .product-time {
          font-size: 12px;
        }

        .product-features .feature-tag {
          font-size: 10px;
          padding: 4px 8px;
        }

        .product-price {
          .original-price {
            font-size: 12px;
          }
          .current-price {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>